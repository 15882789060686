import { motion } from 'framer-motion'

import { Suburbs } from '@/data/getSuburbs'

interface SelectedSuburbChipProps {
  suburb: Suburbs[0]
  onRemove?: (suburb: Suburbs[0]) => void
}

export function SelectedSuburbChip({ suburb, onRemove }: SelectedSuburbChipProps) {
  return (
    <motion.div
      data-cy="suburb-chip"
      className="flex h-8 items-center justify-center rounded-full border border-current bg-neutral-850 pl-3 pr-0.5 pt-0.5 font-sans text-2xs font-semibold uppercase leading-none tracking-widest text-current transition-colors focus-within:ring focus-within:ring-current sm:pl-5 sm:pr-2 sm:text-base"
    >
      <span className="select-none whitespace-nowrap">
        {suburb.suburb} <span className="opacity-50">({suburb.listings})</span>
      </span>

      {onRemove && (
        <button
          className="group mb-0.5 flex h-8 w-8 items-center justify-center rounded-full focus-visible:animate-pulse focus-visible:bg-neutral-50/20 focus-visible:outline-none"
          onClick={() => onRemove(suburb)}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current stroke-[1.14286] group-hover:stroke-2"
          >
            <path d="M11.5706 0.429688L0.427734 11.5725" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.427734 0.429688L11.5706 11.5725" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      )}
    </motion.div>
  )
}
