'use client'

import { createActorContext, shallowEqual } from '@xstate/react'
import { assign, createMachine } from 'xstate'

import { Suburbs } from '@/data/getSuburbs'

const suburbSelectorMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGUCuAjVAndywBswBjAFwHssA6NTHWAYjxIAJYDiTJWNt1YBtAAwBdRKAAOZWAEsS0sgDsxIAB6IAtACYAHAEZKAFgMB2YwE4Dm44N1mAzMYCsAGhABPDZsqO727Y4NtMzMANh0QkIBfSNcaXjxCUgpqHjoU2j5WdlJIegAROGyWWFT0IVEkEEkZOUVlNQR1XTtKEIM7XUEQ0xt7YxDXD0avHz8AoNDwqJiQOJwEjmS5vkoASQVZenLlatl5JUqGq0dKXQMfEL1jE27jQY1dTS8Q3V1tTUdbQJs36NjShZJKjLWBrDYkLa6CoSKR7OqHRCObreQSWUJmYyaULae6NR7PV7vT4WbQ-bR-WYAopLUqgwG1BTMVDiCAAQ04EC2Ih2sIZ9UQx1O5zsl10136plxYsEhgcFmagkE1k+FOW9JpGTpRX2TJZ7Ny-ChPJq+35CCRxhRaJCGKxlyl1ll5gMCqVghVMzV1OBpWY0gU4lQJEoADEyERUGxOQAZMCsgBuYD9AaD20quz5CLxtlal3e1iMPg6d3cGnOJ00dkslaxpLO03+GXVPoyycDwbDEaj9AAqnrOMwAI6oMBYNxpmEm+GgBpBSiCTSvMUfYlKlylxqXMyUfw2EKfcyORzu1VUxLkFu8NtB0PhyOQdJX-DSWAseMv6ToQiMMDFIpcEpNQnKpeVNLM7E0EJDH3QQ7EVIxtAMNpcXUSxtEocwInaMxNBsAIq1PJtvUfHBrw7O8oxI9BmGfV9mAAC2kCAIDABR6BUV99UoVkADNOCwAAKXDBAASkYM9FkvUj-XbW8uwfZYaJfFhGOY1jgIzMCZ0QDFKErYxdDCG12kuOx1yGdQAi8bRrAxEUILMWDNEI+JiMUmSb07e8IGoX8lLozjOC5aEQKnA5tIQOxdKrRVURsXCMSPFCIgrTpLlw4wbKXFz5jc30PPI+SfKYfzihIfVIRCzTp1URBrHQ6VcIcCIYIGDd1CwndjDM+w4IcM4ctwPLWwKygACF8GwLBcgAUQUfiyI00CaoaJpNAMDDOkyp4ekEUIUNMbdDJSpxbIIz0JKBKiyPGyasGmzklrCs0mirPSAlMRwzHeN4OjaizOpsnq7D6gyDEG5trtGgAlQoSDkBQoDI9jArAbi+NHITFTEr1zw1K8YbhhGkYKp64XC2rGjgqCvqrSYul8AwzBQxw52PJwTCc5p+gh4aCZTQrvOu2iVKYli2OQeiyAAd1KsnMwixxHUXV4jzeY9F00ZLdBOdobO+y5AhBxxohmBQyBY+BKlxyTjXJl6XW3CDMoM3CQkZ5n2s6N5KAsGx4MVRzXl5vGpL4O2Fcp9QHMMExnSsXoHHMzxHkoKKrkcxxFzrckLqI0OqNBAA5MgSEYxGsnPSAI60ymj0tFcQaVWsui1jdcO8NWdbMMVLG6owQ8kwvC8rjhq-TZaKaORCMK+ysbXMUkwmThAO6PY8DAXJEAi++xB6ukEwVkGuVsRFp3X8F4jxwj4kJQx4NqCPRzmsfocJdfeL2H+kdWZNkORPlPRA+hHhwSeDra4FhhQliGIZBqnwbRtECDaLOzk86uQLu5AWgCXr9TTlYLKbsPYoXsPoIwgdrCvC3GgxsGCh5YNkl5KMOCswunnCrZcatHLXBXuoRCLQ-Z7VCM0HwHxP742kgLOSQtFIi2YO+GQX4wAsIiig7wOE9pxSPOtXQLNIKGCsi6OmJgdbiLDjdJhClfRyNUuLFRlM4IylJHtJmT9l4wI0AEbcZgrKfEgv0JEZgzFQykZY4qfk5Go3sUcHCpwF5IRgtfOwB0kSxx+I7a42gwjBIYTeCaU1x6TntlmLQFhDA916kEY8D8UlQXIa8DJyCaGUnzvQ-KUjYZsHhv6Em2CJ7PRKRBFoztCEM0Qp7Cy5Y9JVlvn4LETlTaRCAA */
    id: 'SuburbSelector',

    tsTypes: {} as import('./SuburbSelectorContext.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,

    schema: {
      context: {} as {
        selectedSuburbs: Suburbs
        suburbs: Suburbs
        query: string
        input: HTMLInputElement | null
      },
      events: {} as
        | { type: 'Set selected suburbs'; suburbs: Suburbs }
        | { type: 'Deselect suburb'; suburb: Suburbs[0] }
        | { type: 'Enter input'; input: HTMLInputElement }
        | { type: 'Leave input' }
        | { type: 'Show list' }
        | { type: 'Update query'; query: string },
      services: {} as {
        'Listen for arrow keys': { data: undefined }
      },
    },

    context: {
      selectedSuburbs: [],
      suburbs: [],
      query: '',
      input: null,
    },

    states: {
      Suburbs: {
        initial: 'Init',
        states: {
          'Nothing selected': {},
          'Suburbs selected': {
            on: {
              'Deselect suburb': {
                target: 'Selection updated',
                actions: 'Remove suburb from list',
              },
            },
          },
          Init: {
            always: [
              {
                target: 'Suburbs selected',
                cond: 'Suburbs selected',
              },
              {
                target: 'Nothing selected',
              },
            ],
          },
          'Selection updated': {
            always: [
              {
                target: 'Suburbs selected',
                cond: 'Suburbs selected',
              },
              {
                target: 'Nothing selected',
              },
            ],
          },
        },
        on: {
          'Set selected suburbs': {
            target: '.Selection updated',
            actions: 'Set selected suburbs',
          },
        },
      },
      'Suburb input': {
        initial: 'Blurred',

        states: {
          Focused: {
            on: {
              'Leave input': 'Resetting input',

              'Update query': {
                actions: 'Set query',
                target: '.Set list state',
              },
            },

            states: {
              'Suburb list visible': {
                on: {
                  'Set selected suburbs': {
                    target: 'Suburb list hidden',
                    cond: 'Suburb added',
                  },
                },
              },

              'Suburb list hidden': {
                after: {
                  '200': {
                    target: 'Suburb list hidden',
                    internal: true,
                    actions: ['Clear query', 'Focus input'],
                  },
                },

                invoke: {
                  src: 'Listen for arrow keys',
                  id: 'arrowKeyListener',
                },

                on: {
                  'Show list': 'Suburb list visible',
                },
              },

              'Set list state': {
                always: [
                  {
                    target: 'Suburb list hidden',
                    cond: 'Query is empty',
                  },
                  'Suburb list visible',
                ],
              },
            },

            initial: 'Set list state',
          },

          Blurred: {
            on: {
              'Enter input': {
                target: 'Focused',
                actions: 'Set input',
              },
            },

            always: {
              target: 'Focused',
              cond: 'Input is focused',
            },
          },

          'Resetting input': {
            after: {
              '200': {
                target: 'Blurred',
                actions: 'Clear query',
              },
            },
          },
        },
      },
    },

    type: 'parallel',
  },
  {
    guards: {
      'Suburbs selected': ({ selectedSuburbs }) => {
        return selectedSuburbs.length > 0
      },
      'Query is empty': ({ query }) => !query?.length,
      'Suburb added': ({ selectedSuburbs }, { suburbs }) => selectedSuburbs.length < suburbs.length,
      'Input is focused': ({ input }) => !!input && input === document?.activeElement,
    },
    actions: {
      'Remove suburb from list': assign(({ selectedSuburbs }, { suburb }) => ({
        selectedSuburbs: selectedSuburbs.filter((selectedSuburb) => selectedSuburb.suburb !== suburb.suburb),
      })),
      'Set query': assign((_ctx, { query }) => ({ query })),
      'Set selected suburbs': assign((_ctx, { suburbs }) => ({
        selectedSuburbs: suburbs,
      })),
      'Set input': assign((_ctx, { input }) => ({ input })),
      'Focus input': ({ input }) => {
        input?.focus()
      },
      'Clear query': assign(() => ({ query: '' })),
    },
    services: {
      'Listen for arrow keys': () => (send) => {
        const listener = (event: KeyboardEvent) => {
          if (event.key === 'ArrowDown') {
            send('Show list')
          }
        }

        document.addEventListener('keydown', listener)

        return () => {
          document.removeEventListener('keydown', listener)
        }
      },
    },
  },
)

export const SuburbSelectorContext = createActorContext(suburbSelectorMachine)

export function SuburbSelectorProvider({
  children,
  suburbs,
  selectedSuburbs = [],
}: {
  children: React.ReactNode
  suburbs?: Suburbs
  selectedSuburbs?: Suburbs
}) {
  return (
    <SuburbSelectorContext.Provider
      options={{
        context: {
          suburbs,
          selectedSuburbs,
        },
      }}
    >
      {children}
    </SuburbSelectorContext.Provider>
  )
}

export function useFilteredSuburbs() {
  const matchSuburb = ({ suburb, postcode }: Suburbs[0], query: string) =>
    suburb.toUpperCase().replace(/\s+/g, '').includes(query.toUpperCase().replace(/\s+/g, '')) ||
    postcode.indexOf(query) === 0

  return SuburbSelectorContext.useSelector(({ context }) => {
    const { query = '', suburbs = [] } = context

    if (query === '') {
      return suburbs
    }

    return suburbs.filter((suburb) => matchSuburb(suburb, query))
  }, shallowEqual)
}
