import { SVGAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const BedIcon = ({ className, ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      data-cy="beds-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 16"
      fill="none"
      className={twMerge('h-6 w-6 stroke-current', className)}
      {...props}
    >
      <path
        strokeMiterlimit="10"
        d="M2.4 2.8h15.7m-15.7 0v5.3h15.7V2.8m-15.7 0V1m15.7 2v-2M.8 13.2V15m19-2v2m-19-7h19v5H.8v-5ZM4.2 6h5.2V8H4.2V6Zm7 0h5.2V8h-5.2V6Z"
      />
    </svg>
  )
}
