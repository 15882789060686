import { SVGAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const BathroomIcon = ({ className, ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      data-cy="bathroom-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 21"
      fill="none"
      className={twMerge('h-6 w-6 stroke-current', className)}
      {...props}
    >
      <path
        strokeMiterlimit="10"
        d="M4 19.3v1.4m-1.5-7.1V1.3h3.9v2m0 0c-1 0-1.8.8-1.8 1.8h3.6c0-1-.8-1.8-1.8-1.8Zm8.7 16v1.4M6.4 8.4v1.9m1.8-3.8v1.9m-3.6-2v2m3.6 1.9v1.9m-3.6-2v2m13.7 3.4c0 2-1.7 3.7-3.7 3.7H4.5c-2 0-3.7-1.7-3.7-3.7v-2h17.5v2Z"
      />
    </svg>
  )
}
