import { ButtonHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button, ButtonProps } from '@/components/Button'

export function CTAButton({ className, children, ...props }: ButtonProps) {
  return (
    <Button
      className={twMerge(
        'flex h-12 grow-0 items-center justify-center rounded-bl-none rounded-br-3xl rounded-tl-3xl rounded-tr-none border border-white bg-neutral-850 px-6 text-center font-sans text-sm font-extrabold uppercase tracking-wider text-white drop-shadow-md',
        className,
      )}
      {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </Button>
  )
}
