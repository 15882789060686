'use client'

import { FocusEventHandler, Fragment, useEffect, useRef } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import type { ComboboxInputProps } from '@headlessui/react'
import CheckIcon from '@heroicons/react/20/solid/CheckIcon'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import type { Suburbs } from '@/data/getSuburbs'
import { SuburbSelectorContext } from './SuburbSelectorContext'

type Suburb = Suburbs[0]

export interface SuburbSearchInputProps extends ComboboxInputProps<'input', Suburb> {
  suburbs: Suburbs
  query: string
  selectedSuburbs: Suburbs
  showList: boolean
  autofocus?: boolean
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onSelection?: (selection: Suburbs) => void
  onChangeQuery?: (query: string) => void
  buttonClassName?: string
  iconClassName?: string
  inputClassName?: string
}

const SearchIcon = ({ className }: { className?: string }) => (
  <svg
    data-cy="search-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    aria-hidden
    className={className}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.531 25.918c6.842 0 12.389-5.547 12.389-12.389S20.373 1.141 13.53 1.141 1.143 6.687 1.143 13.529 6.689 25.918 13.53 25.918ZM30.857 30.853l-8.572-8.572"
    />
  </svg>
)

const isPostcode = (query: string) => /^\d{1,4}$/.test(query)

export function SuburbSearchInput({
  query,
  suburbs = [],
  selectedSuburbs = [],
  showList,
  autofocus,
  onFocus,
  onBlur,
  onSelection,
  onChangeQuery,
  className,
  buttonClassName,
  iconClassName,
  inputClassName,
  ...props
}: SuburbSearchInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [, sendToSuburbSelector] = SuburbSelectorContext.useActor()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeQuery?.(event.target.value)
  }

  useEffect(() => {
    if (autofocus) {
      if (inputRef.current) {
        inputRef.current?.focus()
        sendToSuburbSelector({ type: 'Enter input', input: inputRef.current })
      }
    }
  }, [autofocus, sendToSuburbSelector])

  return (
    <div
      className={twMerge('relative flex items-start gap-x-3 border-b border-current lg:gap-x-4', className as string)}
    >
      <Combobox value={selectedSuburbs} by="suburb" onChange={onSelection} multiple>
        <>
          <Combobox.Button className={twMerge('relative mb-2 items-center self-end', buttonClassName)}>
            <SearchIcon className={twMerge('h-6 w-6 lg:h-8 lg:w-8', iconClassName)} />
          </Combobox.Button>

          <div className="w-full">
            <div className="group flex w-full items-start rounded">
              <Combobox.Input
                data-cy="suburb-search-input"
                ref={inputRef}
                className={twMerge(
                  'flex w-full flex-1 items-end rounded border-none bg-transparent pb-1 font-sans text-2xl font-semibold uppercase leading-none tracking-widest text-neutral-50 placeholder:uppercase placeholder:transition placeholder:duration-300 focus-visible:outline-none',
                  inputClassName,
                )}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete="off"
                value={query}
                displayValue={(s) => s?.suburb || query}
                {...props}
              />
            </div>
            <Transition
              show={showList}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options
                data-cy="search-dropdown"
                className="scroll-w-0 absolute left-0 z-10 mt-4 max-h-[60vh] w-full overflow-auto bg-neutral-850/60 pl-10 pr-2 pt-4 backdrop-blur-sm focus:outline-none"
              >
                {suburbs.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none py-2.5 font-sans text-xl font-semibold uppercase tracking-widest text-neutral-50/50">
                    Nothing found.
                  </div>
                ) : (
                  suburbs.map((suburb) => (
                    <Combobox.Option
                      key={suburb.suburb}
                      data-cy="search-dropdown-item"
                      className="relative flex h-12 cursor-pointer items-stretch font-sans text-xl font-semibold uppercase leading-normal tracking-widest text-neutral-50"
                      value={suburb}
                    >
                      {({ active, selected }) => (
                        <>
                          {active ? (
                            <motion.span
                              layoutId="active-suburb-indicator"
                              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                              data-cy="search-dropdown-item-indicator"
                              className="indicator before:-translate-x-6 before:translate-y-0.5"
                            />
                          ) : null}
                          <span
                            className={twMerge(
                              'block select-none truncate font-medium',
                              selected ? 'opacity-80' : 'opacity-100',
                            )}
                            aria-selected={selected}
                          >
                            {suburb.suburb}
                          </span>
                          <CheckIcon
                            className={twMerge(
                              'ml-1 mt-1.5 h-4 origin-bottom transition-all',
                              selected ? 'w-4 opacity-80 scale-100' : 'w-0 opacity-0 scale-75',
                            )}
                          />
                          <span
                            className={twMerge(
                              'ml-4 transition-opacity',
                              isPostcode(query) ? 'opacity-50' : 'opacity-0',
                            )}
                          >
                            {suburb.postcode}
                          </span>
                          <span className="flex-1 text-right opacity-50">({suburb.listings})</span>
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </>
      </Combobox>
    </div>
  )
}
