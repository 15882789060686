'use client'

import { MouseEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

import { HeartIcon } from '../icons/HeartIcon'
import { FavouritesContext } from './FavouritesContext'

type FavouriteIconProps = { className?: string; id: string; fillColor?: string }

export const FavouriteIcon = ({ className, id }: FavouriteIconProps) => {
  const [, sendToFavourites] = FavouritesContext.useActor()

  const active = FavouritesContext.useSelector(
    (state) => state.matches('Initialised') && state.context.favourites.includes(id),
  )
  const toggleFavourite: MouseEventHandler<SVGElement> = (e) => {
    e.preventDefault()
    if (active) {
      sendToFavourites({ type: 'Remove from Favourites', id })
    } else {
      sendToFavourites({ type: 'Add To Favourites', id })
    }
  }

  return (
    <div className="grid h-8 w-8 content-around justify-center">
      <HeartIcon
        className={twMerge(
          'pointer-events-none col-start-1 row-start-1 h-8 w-8 fill-transparent transition-colors duration-300',
          active && 'animate-single-ping fill-current',
          className,
        )}
      />
      <HeartIcon
        className={twMerge(
          'col-start-1 row-start-1 h-8 w-8 fill-transparent transition-colors duration-300',
          active && 'fill-current',
          className,
        )}
        onClick={toggleFavourite}
      />
    </div>
  )
}
