'use client'

import { shallowEqual } from '@xstate/react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { Suburbs } from '@/data/getSuburbs'
import { SuburbSelectorContext } from '../SuburbSelector/SuburbSelectorContext'
import { SelectedSuburbChip } from './SelectedSuburbChip'

interface SelectedSuburbsProps {
  className?: string
  onRemove?: (suburb: Suburbs[0]) => void
}

export function SelectedSuburbs({ className, onRemove }: SelectedSuburbsProps) {
  const [, sendToSuburbSelector] = SuburbSelectorContext.useActor()
  const selectedSuburbs = SuburbSelectorContext.useSelector(
    ({ context: { selectedSuburbs } }) => selectedSuburbs,
    shallowEqual,
  )

  const handleRemove = (suburb: Suburbs[0]) => {
    sendToSuburbSelector({ type: 'Deselect suburb', suburb })
    onRemove?.(suburb)
  }

  const variants = {
    in: { opacity: 1, scale: 1 },
    out: { opacity: 0, scale: 0 },
  }

  return (
    <motion.ul
      layout
      className={twMerge('flex w-full flex-wrap gap-3 gap-y-4 pb-8 pt-6 text-neutral-50 lg:gap-5 lg:pt-8', className)}
      transition={{ type: 'tween' }}
    >
      <AnimatePresence initial={false}>
        {selectedSuburbs.map((suburb) => (
          <motion.li
            key={suburb.suburb}
            className="flex"
            layoutId={suburb.suburb}
            variants={variants}
            initial="out"
            animate="in"
            exit="out"
            transition={{ duration: 0.3 }}
          >
            <SelectedSuburbChip suburb={suburb} onRemove={handleRemove} />
          </motion.li>
        ))}
      </AnimatePresence>
    </motion.ul>
  )
}
