import { Suburbs } from '@/data/getSuburbs'

export const parseSuburbParams = (suburbsParam: string[]) => parseSuburbSlugs(suburbsParam?.[0])

export function normalizeSuburb(suburb: string) {
  return suburb.replace(/\s+/g, '-').toLowerCase()
}

// function to take a string containing suburbs and West Australian postcodes joined by a non-alphanumeric character and return an array of slugified, lowercase, suburbs and postcodes
//
// Usage: const regions = parseSuburbSlugs('suburb+mount-suburb+6000+6001')
//              ^ ['suburb', 'mount suburb', '6000', '6001']
export function parseSuburbSlugs(suburbSlugs = '') {
  return decodeURIComponent(suburbSlugs)
    .split(/[^a-zA-Z0-9-]+/)
    .map((region) => region.toLowerCase().replace(/-/g, ' '))
    .filter((region) => region !== '')
}

export function matchSuburbs(suburbs: Suburbs, suburbSlugs: string[]): Suburbs {
  return suburbSlugs.reduce((acc, suburbOrPostcode) => {
    const found = suburbs.find(
      ({ suburb, postcode }) => suburb.toLowerCase() === suburbOrPostcode || postcode === suburbOrPostcode,
    )
    if (found) {
      acc.push(found)
    }
    return acc
  }, [] as Suburbs)
}

export function suburbsToFilterString(suburbs: Suburbs) {
  return suburbs.map(({ suburb }) => suburb.replace(/\s+/g, '-').toLowerCase()).join('+')
}
