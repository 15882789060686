'use client'

import { ReactNode } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'

import { smoothScrollTo } from '@/utils/smoothScrollTo'
import { CTAButton } from '../CTAButton'
import { StickyCTABar } from '../StickyCTABar'

interface FloatingAnchorButtonProps {
  target: string
  children?: string
  className?: string
  breakPoint?: 'sm' | 'md'
  scrollOptions?: ScrollIntoViewOptions
  additionalElements?: ReactNode
  containerClassName?: string
}

export function FloatingAnchorButton({
  target,
  children,
  className,
  breakPoint = 'md',
  scrollOptions,
  additionalElements,
  containerClassName,
}: FloatingAnchorButtonProps) {
  const handleClick = () => smoothScrollTo(target, scrollOptions)

  const classNameBp =
    breakPoint === 'sm'
      ? 'sm:bottom-32 sm:top-0 sm:order-[unset] sm:justify-end sm:px-20'
      : 'md:bottom-32 md:top-0 md:order-[unset] md:justify-end md:px-20'
  const innerClassNameBp =
    breakPoint === 'sm'
      ? 'sm:top-12 sm:mt-12 sm:justify-end sm:transition-none'
      : 'md:top-12 md:mt-24 md:justify-end md:transition-none'

  return (
    <StickyCTABar
      className={twMerge('order-1 w-full justify-center p-5 sm:px-10', classNameBp, className)}
      innerClassName={twJoin('justify-center bottom-12', innerClassNameBp)}
    >
      <div className={twMerge('z-10 flex grow flex-col gap-4', containerClassName)}>
        <CTAButton onClick={handleClick} className="h-12 shrink-0">
          {children || target}
        </CTAButton>
        {additionalElements}
      </div>
    </StickyCTABar>
  )
}
