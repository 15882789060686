import { PropsWithChildren, ReactNode } from 'react'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

import defaultImage from '@/assets/placeholder.png'

type PreviewImageProps = PropsWithChildren<{
  className?: string
  url: string
  blurUrl?: string
  priority?: boolean
  heart?: ReactNode
}>

export function PreviewImage({ className, url, blurUrl, priority, heart, children }: PreviewImageProps) {
  return (
    <div className={twMerge('relative aspect-photo w-full bg-gradient-to-b from-zinc-700 to-zinc-500', className)}>
      {heart && <div className="absolute right-4 top-4 z-10 text-white">{heart}</div>}
      {children}
      <Image
        src={url?.replace(/^\/\//, 'https://') ?? defaultImage.src}
        blurDataURL={blurUrl}
        placeholder={blurUrl ? 'blur' : 'empty'}
        loading={priority ? 'eager' : 'lazy'}
        priority={priority}
        alt=""
        fill
        className="object-cover"
        sizes="(max-width: 640px) 100vw,
              (max-width: 1024px) 50vw,
              720px"
      />
    </div>
  )
}
