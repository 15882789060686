import { twMerge } from 'tailwind-merge'

interface StickyCTABarProps {
  children: React.ReactNode
  className?: string
  innerClassName?: string
  stickyClassName?: string
}

export function StickyCTABar({ children, className, innerClassName, stickyClassName = 'sticky' }: StickyCTABarProps) {
  return (
    <div
      className={twMerge(
        'pointer-events-none absolute inset-y-0 right-0 z-50 flex flex-col md:flex-col-reverse md:items-end',
        className,
      )}
    >
      <div className="grow-1 h-full w-full"></div>

      <div className={twMerge('pointer-events-auto mx-auto flex grow-0 md:mr-0', stickyClassName, innerClassName)}>
        {children}
      </div>
    </div>
  )
}
