import { SVGAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const GarageIcon = ({ className, ...props }: SVGAttributes<SVGElement>) => (
  <svg
    data-cy="garage-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 21"
    fill="none"
    className={twMerge('h-6 w-6 stroke-current', className)}
    {...props}
  >
    <path
      strokeMiterlimit="10"
      vectorEffect="non-scaling-stroke"
      d="M4.5 8.7 2 13h14l-2.4-4.4H4.5Zm0 0L7 11m10.9-4.6L9 1.3.3 6.5M7 8.7 9.6 11m1 5.5h-3M.7 13.1h16.6v5H.8v-5Zm1.3 5h2.4v2H2v-2Zm11.6 0H16v2h-2.3v-2Zm-9.9-2.6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm11.6 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
)
